import { useDispatch, useSelector } from "react-redux";
import { containerTypes, eventHandlers, inputTypes, specialTypes, containerLayouts } from "../config/configEnums";
import { Form, FloatingLabel, Accordion, FormLabel } from "react-bootstrap";
import { Box, Select, FormControl, InputLabel, MenuItem, Button, FormGroup, TextField, IconButton, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getMonth, getYear, parseISO } from 'date-fns';
import range from "lodash/range";
import MaskedTextInput from 'react-text-mask'
import 'dayjs/locale/de';
/* import { selectConfigUserData, tableSelectConfig } from "../screens/user/dataCollect/selectboxConfig"; */
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { MdClear } from "react-icons/md";
import dayjs from 'dayjs';
import { setFormState, FormState, setErrors, getFormCheckDone, setFormCheckDone, FormCheckDoneOptions, getCurrentAge, setCurrentAge, getErrors } from "../features/form/formSlice";
import { toast } from 'react-toastify';
import { useEffect, useState, memo } from "react";
import { useLayoutEffect } from "react";
import ClientTable from "../screens/user/dataCollect/ClientTable";
import BasicTable from "../screens/user/projectData/BasicTable";
import { getUser } from "../features/user/userSlice";
const _ = require("lodash")
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

let thisExtraColumns;
let requiredDependencies = [];

function arrayEquals(array1, array2) {
    if (!array1 || !array2) {
        return false;
    }
    if (array1 === array2) {
        return true;
    }
    if (array1.length !== array2.length)
        return false;

    for (var i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!arrayEquals(array1[i], array2[i]))
                return false;
        }
        else if (array1[i] !== array2[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}

function hasDuplicates(array) {
    return (new Set(array)).size !== array.length
}

const GenericForm = ({ formConfig, databaseObject, setDatabaseObject, displayNumberOfField, searchField = false }) => {
    const user = useSelector(getUser);

    function getTableSelectConfig() {
        if (user.userProject.projectType.id !== 1) {
            return formConfig.tableSelectConfig;
        }

        const offerType = user.userProject.projectOffer.projectOfferType.toLowerCase()
        if (offerType.includes('notuebernachtung fuer familien') || offerType.includes('notübernachtung für familien')) {
            return formConfig.tableSelectOvernightFamilyConfig;
        }
        else if (offerType.includes('notuebernachtung') || offerType.includes('notübernachtung')) {
            return formConfig.tableSelectOvernightConfig;
        }
        else if (offerType.includes('housing first')) {
            return formConfig.tableSelectHouseVisitConfig;
        }
        else if (offerType.includes('medizinische versorgung')) {
            return formConfig.tableSelectMedicalCareConfig;
        }
        else if (offerType.includes('psychologische beratungsstelle')) {
            return formConfig.tableSelectPsychologicalCounselingConfig;
        }
        else {
            return formConfig.tableSelectConfig;
        }
    }

    const [configInvalid, setConfigInvalid] = useState("");
    const [sortedFormContainers, setSortedFormContainers] = useState([...formConfig.containers].sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)));
    const [genericFormContainer, setGenericFormContainer] = useState(sortedFormContainers.find(obj => obj.containerType === containerTypes.GENERICFORM));
    const [checkRemoveField, setCheckRemoveField] = useState(genericFormContainer?.elements?.find(element => element.specialTypeField === specialTypes.WITHCHECKREMOVE));
    const [tableSelectConfig, setTableSelectConfig] = useState(getTableSelectConfig());
    const [wrongDateInput, setWrongDateInput] = useState([]);

    const formCheckDone = useSelector(getFormCheckDone);
    const errors = useSelector(getErrors);
    const selectConfigUserData = formConfig.selectConfigUserData;

    const dispatch = useDispatch();

    useLayoutEffect(() => {
        if (genericFormContainer) {
            genericFormContainer.elements.forEach(obj => {
                if (obj.required) {
                    requiredDependencies.push(databaseObject[obj.value]);
                }
            })
        }

        let extraColumnTable;
        for (let i = 0; i < sortedFormContainers.length; i++) {
            if (sortedFormContainers[i].containerType === containerTypes.ADDROWTABLECONTAINER) {
                extraColumnTable = sortedFormContainers[i].tables.find((table) => table.specialTypeTable?.[0] === specialTypes.WITHEXTRACOLUMNS);
                break;
            }
        }
        if (extraColumnTable) {
            let checkExtraColumns = _.get(user, extraColumnTable.specialTypeTable[2]).toLowerCase()
            thisExtraColumns = checkExtraColumns.includes(extraColumnTable.specialTypeTable[1]);
        }

        const tempErrors = { ...errors }
        Object.keys(tempErrors).forEach((i) => tempErrors[i] = null)
        dispatch(setErrors({ ...tempErrors }));

        /* if (genericFormContainer) {


            sortedFormContainers.every(container => {
                if (container.containerType !== containerTypes.ADDROWTABLECONTAINER) {
                    let existingSpecialTypes = [];
                    const specialTypeFields = [];

                    const containerValid = container.elements.every(obj => {
                        if (container.containerType !== containerTypes.GENERICFORM) {
                            if (obj.inputType === inputTypes.DISPLAY) {
                                return false;
                            }
                            if (genericFormOnlyTypes.includes(genericFormOnlyType => genericFormOnlyType === obj.specialTypeField)) {
                                return false;
                            }
                        }
                        else if (obj.inputType === inputTypes.DISPLAY && obj.specialTypeField !== specialTypes.WITHAGEFIELD) {
                            return false;
                        }

                        if (obj.specialType) {
                            existingSpecialTypes = existingSpecialTypes.concat(obj.specialType);
                        }

                        if (obj.specialTypeField && genericFormOnlyTypes.includes(obj.specialTypeField)) {
                            specialTypeFields.push(obj.specialTypeField)
                        }

                        return true;
                    });

                    if (containerValid) {
                        if (hasDuplicates(specialTypeFields)) {
                            setConfigInvalid("Config ist ungültig. Es gibt mehrere nicht duplizierbare specialTypeFields")
                            return false
                        }
                        else if (!arrayEquals(existingSpecialTypes.sort(), specialTypeFields.sort())) {
                            setConfigInvalid("Config ist ungültig. Die Zahl der specialTypeFields stimmt nicht mit der Zahl der specialTypes überein");
                            return false
                        }
                    }
                    else {
                        setConfigInvalid(true);
                        return false
                    }
                    return true
                }
                else {
                    return true;
                }
            })
        }
        else {
            setConfigInvalid("Config ist ungültig. Es gibt keinen generischen Form Container");
        } */
    }, []);

    useEffect(() => {
        if (formCheckDone === FormCheckDoneOptions.CHECKING) {
            checkFormValidity(databaseObject);
            dispatch(setFormCheckDone(FormCheckDoneOptions.YES));
        }
    }, [formCheckDone])

    function isUnderlineCell(index, containerType) {
        if (containerType === containerTypes.GENERICFORM) {
            if (genericFormContainer.elements.length % 4 === 0 && (index + 1) > (genericFormContainer.elements.length - 4)) {
                return 'normalCell';
            }
            else {
                return genericFormContainer.elements.length >= (Math.ceil((index + 1) / 4) * 4) ? 'underlineCell' : 'normalCell';
            }
        }
        else {
            return "";
        }
    }

    // call this function from parent for it to be less redundant
    const checkFormValidity = (databaseObject) => {
        const newErrors = {};
        let checkFields = [];

        for (let i = 0; i < sortedFormContainers.length; i++) {
            const tempCheckFields = sortedFormContainers[i].elements?.reduce((array, element) => {
                if (element.required === true || element.inputType === inputTypes.DATE) {
                    array.push(element);
                }

                return array;
            }, []);

            if (tempCheckFields) {
                checkFields = checkFields.concat(tempCheckFields);
            }
        }

        for (let i = 0; i < checkFields.length; i++) {
            if (checkFields[i].inputType !== inputTypes.DATE && checkFields[i].required && (!databaseObject[checkFields[i].value] || databaseObject[checkFields[i].value] === '')) {
                newErrors[checkFields[i].value] = `${checkFields[i].name} ist ein Pflichtfeld.`
            }
            else if (checkFields[i].inputType === inputTypes.DATE && wrongDateInput.includes(checkFields[i].value)) {
                newErrors[checkFields[i].value] = `${checkFields[i].name} ist ungültig.`
            }
        }

        if (errors.tableErrors) {
            newErrors.tableErrors = errors.tableErrors;
            toast.error("Bitte Tabelleneingabe prüfen.");
        }

        dispatch(setErrors(newErrors));
        return newErrors;
    }

    /**
     * Speichert zu löschende Werte des Klienten beim Alter von unter 18 in dem Objekt 'deleteProperties'
     * 
     * Felder außerhalb der Soziodemografischen Daten müssen separat angegeben werden
     */
    function handleUnderageSelect(container) {
        const filteredContainer = { ...container }

        filteredContainer.elements = container.elements.filter(element => {
            if (element.keepOnCheck) {
                return true;
            }
        });

        return <ContainerContent
            container={filteredContainer}
            databaseObject={databaseObject}
            selectConfigUserData={selectConfigUserData}
            setDatabaseObject={setDatabaseObject}
            isUnderlineCell={isUnderlineCell}
            errors={errors} />;
    }

    function checkRemoveFields(container) {
        return container.specialTypeContainer?.[0] === specialTypes.WITHCHECKREMOVE && checkRemoveField && databaseObject[checkRemoveField?.value]
    }

    function getContainerLayout(container) {
        switch (container.containerLayout) {
            case containerLayouts.GRID3:
                return "generic-grid3-container";

            case containerLayouts.GRID4:
                return "generic-grid4-container";

            case containerLayouts.LIST:
                return "generic-list-container";

            default: return "";
        }
    }

    function hasExtraColumns(table) {
        if (table.specialTypeTable && table.specialTypeTable?.[0] === specialTypes.WITHEXTRACOLUMNS) {
            let checkExtraColumns = _.get(user, table.specialTypeTable[2]).toLowerCase()
            let thisExtraColumns = checkExtraColumns.includes(table.specialTypeTable[1]);

            return thisExtraColumns;
        }
        else return false;
    }

    useEffect(() => {
        if (requiredDependencies.some(field => field.length >= 1)) {
            const editedValues = requiredDependencies.filter(field => field.length >= 1);
            const uniqueEditedValues = [...new Set(editedValues)];
            const editedKeys = [];
            const removeErrors = {};

            uniqueEditedValues.forEach(value => {
                Object.keys(databaseObject).filter(key => databaseObject[key] === value).forEach(key => {
                    editedKeys.push(key);
                });
            });

            editedKeys.forEach(key => removeErrors[key] = null);

            dispatch(setErrors({ ...errors, ...removeErrors }));
        }
    }, requiredDependencies)


    if (configInvalid) {
        return (
            <div>
                {configInvalid}
            </div>
        )
    }
    else {
        return (
            <div className="generic-form-root">
                {sortedFormContainers.map((container) => {
                    switch (container.containerType) {

                        case containerTypes.GENERICFORM:
                            return (
                                <div key={container.position} className={`row mx-auto generic-form ${container.cssClass ? container.cssClass : ""} ${getContainerLayout(container)}`} style={{ maxWidth: '100%', width: '100%' }}>
                                    {databaseObject.priorityDisability === 1 && container.cssClass === "multiple-disabilities" &&
                                        <div className="blur-overlay">
                                            <p>Bitte geben sie zuerst eine vorrangige Behinderung an</p>
                                        </div>    
                                    }
                                    {container.header &&
                                        <h6>{container.header}</h6>
                                    }
                                    <ContainerContent
                                        container={container}
                                        selectConfigUserData={selectConfigUserData}
                                        databaseObject={databaseObject}
                                        setDatabaseObject={setDatabaseObject}
                                        isUnderlineCell={isUnderlineCell}
                                        checkRemoveField={checkRemoveField}
                                        wrongDateInput={wrongDateInput}
                                        setWrongDateInput={setWrongDateInput}
                                        displayNumberOfField={displayNumberOfField?.containerPosition === container.position ? displayNumberOfField : null} />
                                    {requiredDependencies.length > 0 && container.cssClass === "user-info-table" &&
                                        <span className="mandatory-field-info">* = Pflichtfeld</span>}
                                </div>
                            )

                        case containerTypes.ACCORDION:

                            return (
                                <div id="data-collect-accordion" className={`${container.cssClass ? container.cssClass : ""}`} key={container.position}>
                                    <Accordion className='row mx-auto' defaultActiveKey="0" flush>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className='border'>{container.header}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className={`row client-select-boxes ${getContainerLayout(container)}`} style={{ marginTop: '0.3em' }}>
                                                    {checkRemoveFields(container) ?
                                                        handleUnderageSelect(container)
                                                        :
                                                        (
                                                            <ContainerContent
                                                                container={container}
                                                                selectConfigUserData={selectConfigUserData}
                                                                databaseObject={databaseObject}
                                                                setDatabaseObject={setDatabaseObject}
                                                                wrongDateInput={wrongDateInput}
                                                                setWrongDateInput={setWrongDateInput}
                                                                isUnderlineCell={isUnderlineCell}
                                                                checkRemoveField={checkRemoveField}
                                                                displayNumberOfField={displayNumberOfField?.containerPosition === container.position ? displayNumberOfField : null} />
                                                        )
                                                    }
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            );

                        case containerTypes.ADDROWTABLECONTAINER:
                            return (
                                <div style={{ position: "relative" }} key={container.position}>
                                    <div className={`client-table-container ${container.cssClass ? container.cssClass : ""}`} style={{ width: `${thisExtraColumns ? '90vw' : '100vw'}`, transform: `${thisExtraColumns ? 'translateX(-15%)' : 'translateX(-19%)'}` }}>
                                        {container.tables.map(table => {
                                           
                                            return (
                                                <ClientTable
                                                    key={table.tableName}
                                                    databaseObject={databaseObject}
                                                    setDatabaseObject={setDatabaseObject}
                                                    tableElement={table}
                                                    config={tableSelectConfig ? tableSelectConfig[table.tableName] : null}
                                                    errors={errors}
                                                    extraColumns={hasExtraColumns(table)}
                                                />
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            );

                        case containerTypes.BASICTABLECONTAINER:
                            return (
                                <div key={container.position} className="basic-table-container">
                                    <div>
                                        <BasicTable container={container} databaseObject={databaseObject} setDatabaseObject={setDatabaseObject} />
                                    </div>
                                </div>
                            )

                        default:
                            return null;
                    }
                })
                }
            </div>
        )
    }
}

const ContainerContent = memo(function ContainerContent({ container, selectConfigUserData, isUnderlineCell, databaseObject, setDatabaseObject, checkRemoveField, displayNumberOfField, wrongDateInput, setWrongDateInput }) {

    const [underageObject, setUnderageObject] = useState(container.elements?.find(obj => obj.specialType?.includes(specialTypes.WITHCHECKREMOVE)));
    const [containsRadio, setContainsRadio] = useState(container.elements?.some(obj => obj.inputType === inputTypes.RADIO));

    const currentAge = useSelector(getCurrentAge);
    const errors = useSelector(getErrors);

    const dispatch = useDispatch();

    const currentUser = JSON.parse(localStorage.getItem('user'));

    const accessObjects = { currentUser }

    function getTime(key) {
        if (databaseObject[key]) {
            if (typeof databaseObject[key] == "string") {
                return parseISO(databaseObject[key]);
            }
            if (typeof databaseObject[key] == "object") {
                return databaseObject[key];
            }
        }
        else return '';
    }

    function handleChange(e, check) {
        dispatch(setFormState(FormState.MODIFIED));
        if (!check) {
            if (e.target.name === "priorityDisability") {
                if(e.target.value === 1) {
                    setDatabaseObject({ ...databaseObject, [e.target.name]: e.target.value, mentalDisability: false, physicalDisability: false, psychologicalDisability: false, sensoryDisability: false, autism: false });
                }
                else {
                    const disabilities = ["", "", "mentalDisability", "physicalDisability", "sensoryDisability", "psychologicalDisability", "autism"];
                    setDatabaseObject({ ...databaseObject, [e.target.name]: e.target.value, [disabilities[e.target.value]]: false });
                }
            }
            else {
                setDatabaseObject({ ...databaseObject, [e.target.name]: e.target.value });
            }
        }
        else {
            setDatabaseObject({ ...databaseObject, [e.target.name]: e.target.checked });
        }
    }

    function handleDateChange(enteredDate, field, contains) {
        dispatch(setFormState(FormState.MODIFIED));
        calcDate(enteredDate, field, contains);
    }

    function calcDate(enteredDate, field, contains) {
        var date = new Date(enteredDate);
        var age = calcAgeFormula(date);

        if (contains?.ageField) {
            dispatch(setCurrentAge(age));
        }

        if (contains?.underage) {
            if (age >= 18) {
                setDatabaseObject({ ...databaseObject, [field.value]: date, [checkRemoveField.value]: false });
            }
            else {
                setDatabaseObject({ ...databaseObject, [field.value]: date, [checkRemoveField.value]: true });
            }
        }
        else {
            setDatabaseObject({ ...databaseObject, [field.value]: date })
        }
    }

    function calcAgeFormula(birthday) {
        var todayDate = new Date();
        var age = todayDate.getFullYear() - birthday.getFullYear();
        var m = todayDate.getMonth() - birthday.getMonth();
        if (m < 0 || (m === 0 && todayDate.getDate() < birthday.getDate())) {
            age--;
        }

        return age;
    }

    function handleRadioUnselect(radioGroupValue, radioValue) {
        if (databaseObject[radioGroupValue] === radioValue) {
            setDatabaseObject({ ...databaseObject, [radioGroupValue]: '' });
        }
    }

    function isRadioChecked(value) {
        const radioContainer = container.elements.find(obj => obj.value === value);
        let radioChecked = false;

        if (radioContainer && radioContainer.radios) {
            radioChecked = radioContainer.radios.some(obj => obj.value === databaseObject[value]);
        }

        return radioChecked
    }

    function getInputElement(obj, value, index) {
        switch (obj.inputType) {

            case inputTypes.TEXT:
                return (
                    <div key={obj.value} className={isUnderlineCell(index, container.containerType)}>
                        <Box className="mb-1 mt-1">
                            <FormControl className="mui-formControl">
                                <TextField
                                    className="mui-input"
                                    name={obj.value}
                                    label={obj.name}
                                    error={!!errors[obj.value]}
                                    helperText={errors[obj.value]}
                                    size={container.containerType === containerTypes.GENERICFORM ? "small" : "normal"}
                                    variant={container.inputVariant ? container.inputVariant : "outlined"}
                                    //isInvalid={obj.required ? !!errors[obj.value] : false}
                                    value={value || ''}
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>
                        </Box>
                        {obj.required &&
                            <span className="mandatory-field-marker">*</span>}
                    </div>
                );

            case inputTypes.DATE:
                return (
                    <div key={obj.value} className={isUnderlineCell(index, container.containerType)}>
                        <Form.Group className={`floating-label-container mb-1 mt-1 mui-formControl ${obj.cssClass ? obj.cssClass : ""}`} controlId="formBasicData">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                                <DesktopDatePicker
                                    label={obj.name}
                                    className="mui-input"
                                    slotProps={{
                                        textField: { size: `${container.containerType === containerTypes.GENERICFORM ? "small" : "normal"}`, error: !!errors[obj.value], helperText: errors[obj.value] }, field: {
                                            clearable: true
                                        }
                                    }}
                                    value={value ? dayjs(getTime(obj.value)) : null}
                                    minDate={dayjs().subtract(130, 'year')}
                                    maxDate={dayjs()}
                                    onChange={(date) => {
                                        const containsGenericForm = {};
                                        if (container.containerType === containerTypes.GENERICFORM) {
                                            containsGenericForm.ageField = obj.specialType?.includes(specialTypes.WITHAGEFIELD);
                                            containsGenericForm.underage = obj.specialType?.includes(specialTypes.WITHCHECKREMOVE);
                                        }
                                        if (date !== null && date.isValid() && date.isSameOrBefore(dayjs()) && date.isSameOrAfter(dayjs().subtract(130, 'year'))) {
                                            const preLength = wrongDateInput.length;
                                            const tempWrongDate = wrongDateInput.filter((string) => string !== obj.value)
                                            setWrongDateInput(tempWrongDate);
                                            if (preLength !== tempWrongDate.length) {
                                                dispatch(setErrors({ ...errors, [obj.value]: null }));
                                            }
                                            //setForm({ ...form, birthday: date });
                                            handleDateChange(date, obj, containsGenericForm);
                                        }
                                        else {
                                            if (date !== null) {
                                                setWrongDateInput([...wrongDateInput, obj.value]);
                                            }
                                            else {
                                                // remove this date field from wrongDateInput when input gets cleared
                                                const preLength = wrongDateInput.length;
                                                const tempWrongDate = wrongDateInput.filter((string) => string !== obj.value)
                                                setWrongDateInput(tempWrongDate);
                                                if (preLength !== tempWrongDate.length) {
                                                    dispatch(setErrors({ ...errors, [obj.value]: null }));
                                                }
                                                setDatabaseObject({ ...databaseObject, [obj.value]: "", ...(containsGenericForm.underage && { [checkRemoveField.value]: false }) });
                                            }

                                            if (containsGenericForm.ageField) {
                                                dispatch(setCurrentAge(""));
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Form.Group>
                    </div>
                )

            case container.containerType === containerTypes.GENERICFORM && inputTypes.DISPLAY:

                return (
                    <div key={index} className={isUnderlineCell(index, container.containerType)}>
                        <Box className="mb-1 mt-1">
                            <FormControl className="mui-formControl">
                                <TextField
                                    className="mui-input"
                                    label={obj.name}
                                    sx={{ pointerEvents: "none" }}
                                    readOnly
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    size={container.containerType === containerTypes.GENERICFORM ? "small" : "normal"}
                                    variant="outlined"
                                    //isInvalid={obj.required ? !!errors[obj.value] : false}
                                    value={currentAge === "" ? "" : currentAge}
                                />
                            </FormControl>
                        </Box>
                    </div>
                );

            case inputTypes.CHECKBOX:
                return (
                    <div key={obj.value} className={`genericCheckbox ${isUnderlineCell(index, container.containerType)}`}>
                        {obj.style === "slider" ? (
                            <div className="checkbox-wrapper-3">
                                <div>
                                    <input type="checkbox" id={index} name={obj.value} disabled={obj.specialTypeField === specialTypes.WITHCHECKREMOVE ? !!databaseObject[underageObject?.value] : false} checked={value} onChange={(e) => handleChange(e, true)} />
                                    <label htmlFor={index} className="toggle"><span></span></label>
                                </div>
                                <label htmlFor={index}>{obj.name}</label>
                            </div>
                        ) : (
                            <div>
                                <Form.Group key={obj.value} className="center-child-vertically sd-checkbox">
                                    <Form.Check
                                        type='checkbox'
                                        label={obj.name}
                                        name={obj.value}
                                        disabled={obj.deactivateDisability ? databaseObject.priorityDisability === obj.deactivateDisability || databaseObject.priorityDisability === 1 : false}
                                        checked={value}
                                        onChange={(e) => {
                                            handleChange(e, true);
                                        }}
                                    ></Form.Check>
                                </Form.Group>
                            </div>
                        )
                        }
                    </div>
                )

            case inputTypes.NUMBER:
                return (
                    <div key={index} className={isUnderlineCell(index, container.containerType)}>
                        <Box className="mb-1 mt-1">
                            <FormControl className="mui-formControl">
                                <TextField
                                    className="mui-input"
                                    name={obj.value}
                                    label={obj.name}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    size={container.containerType === containerTypes.GENERICFORM ? "small" : "normal"}
                                    variant="outlined"
                                    inputProps={{ maxLength: obj.maxLength }}
                                    //isInvalid={obj.required ? !!errors[obj.value] : false}
                                    value={value ? value : (value === 0 ? value : '')}
                                    onChange={(e) => {
                                        if (obj.min && Number(e.target.value) < obj.min) {
                                            e.preventDefault();
                                        }
                                        else if (obj.max && Number(e.target.value) > obj.max) {
                                            e.preventDefault();
                                        }
                                        else {
                                            if (obj.specialTypeField === specialTypes.WITHDISPLAYNUMBEROFFIELDS) {
                                                //sets formular as edited => leavin page confirmation dialog appears
                                                dispatch(setFormState(FormState.MODIFIED));
                                                const tempArray = [...databaseObject[obj.value]]
                                                tempArray[index] = e.target.value;
                                                setDatabaseObject({ ...databaseObject, [obj.value]: tempArray });
                                            }
                                            else {
                                                handleChange(e);
                                            }
                                        }
                                    }}
                                />
                                {obj.required ? (
                                    <Form.Control.Feedback type="invalid">
                                        {errors[obj.value]}
                                    </Form.Control.Feedback>
                                ) : null}
                            </FormControl>
                        </Box>
                    </div>
                )

            case inputTypes.SELECT:
                return (
                    <div key={obj.value} className={isUnderlineCell(index, container.containerType)}>
                        {obj.specialTypeField === specialTypes.WITHDEPENDENTSELECTION ? (
                            <Box className={`mb-1 mt-1`} sx={{ position: 'relative' }}>
                                <FormControl sx={{ width: '100%' }} className="mui-formControl">
                                    <InputLabel id={obj.value}>{obj.name}</InputLabel>
                                    <Select
                                        className="mui-input"
                                        labelId={obj.value}
                                        name={obj.value}
                                        label={obj.name}
                                        size={container.containerType === containerTypes.GENERICFORM ? "small" : "normal"}
                                        MenuProps={{
                                            disableScrollLock: false,
                                        }}
                                        value={_.get(accessObjects, obj.dependentSelection)?.includes(databaseObject[obj.value]) ? databaseObject[obj.value] : ''}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {_.get(accessObjects, obj.dependentSelection)?.map((option, key) => (
                                            <MenuItem key={key} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {databaseObject[obj.value] && (
                                    <Button
                                        style={{ position: "absolute", width: "fit-content", right: 30, top: "50%", transform: "translateY(-50%)", color: "grey", minWidth: "none", padding: "0.1rem" }}
                                        className='clear-filter'
                                        onClick={() => setDatabaseObject({ ...databaseObject, [obj.value]: "" })}>
                                        <MdClear size="26" />
                                    </Button>
                                )}
                            </Box>
                        ) : (
                            <Box className={`mb-1 mt-1 ${value === 1 ? "noInfo" : ""}`}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id={obj.value}>{obj.name}</InputLabel>
                                    <Select
                                        className="mui-input"
                                        labelId={obj.value}
                                        name={obj.value}
                                        label={obj.name}
                                        size={container.containerType === containerTypes.GENERICFORM ? "small" : "normal"}
                                        MenuProps={{
                                            disableScrollLock: false,
                                        }}
                                        value={value || 1}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {selectConfigUserData?.[obj.value]?.map((option, key) => (
                                            <MenuItem key={key} value={option.id}>{option.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </div>
                )

            case inputTypes.MULTILINE:
                return (
                    <Box key={index} className={`mb-1 mt-1 ${isUnderlineCell(index, container.containerType)}`}>
                        <FormControl sx={{ width: '100%' }} className="mui-formControl">
                            <TextField
                                className="mui-input"
                                label={obj.name}
                                value={containsRadio && isRadioChecked(obj.value) ? '' : (value || "")}
                                name={obj.value}
                                size={container.containerType === containerTypes.GENERICFORM ? "small" : "normal"}
                                multiline
                                maxRows={obj.maxRows}
                                sx={{ width: '100%', }}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                            />
                        </FormControl>
                    </Box>
                )

            case inputTypes.HEADER:
                return (
                    <div key={obj.name}>
                        <h6>{obj.name}</h6>
                    </div>
                )

            case inputTypes.RADIO:
                return (
                    <FormControl key={obj.value} className="mui-formControl">
                        <FormLabel>{obj.name}</FormLabel>
                        <RadioGroup
                            row
                            name={obj.value}
                            value={value}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                        >
                            {obj.radios.map((radio) => (
                                <FormControlLabel key={radio.value} value={radio.value} control={<Radio onClick={() => handleRadioUnselect(obj.value, radio.value)} />} label={radio.name} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                )

            case inputTypes.PARAGRAPH:
                return (
                    <p key={index}>{obj.name}</p>
                )

            default:
                return null;
        }
    }

    return (
        <div>
            <div className="container-content">
                {container?.elements.map((obj, index) => {
                    if (obj.specialTypeField !== specialTypes.WITHDISPLAYNUMBEROFFIELDS) {
                        return getInputElement(obj, databaseObject?.[obj.value], index);
                    }
                    else return null;
                })}
            </div>
            {displayNumberOfField && databaseObject[displayNumberOfField?.depends] && typeof Number(databaseObject[displayNumberOfField?.depends] === "number") ? (
                <div className="extra-fields">
                    {Array.apply(null, Array(Number(databaseObject[displayNumberOfField.depends]))).map((obj, index) => {
                        const tempObj = { ...displayNumberOfField };
                        tempObj.name = `${tempObj.name} ${index + 1}`;
                        tempObj.inputType = inputTypes.NUMBER;
                        return getInputElement(tempObj, databaseObject[displayNumberOfField.value][index], index);
                    })}
                </div>
            ):""}
        </div>
    )
});

export default GenericForm;