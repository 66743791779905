import { containerTypes, inputTypes, eventHandlers, specialTypes, containerLayouts } from "../../config/configEnums"
import { projectData } from "./BHProjectData"
import { selectConfigUserData, tableSelectConfig } from "./disabilityAssistanceSelect"
import { event, eventStats, eventNotes } from "./events/formEvents"

export const disabilityAssistanceConfig = {
    dataCollection: {
        containers: [
            {
                containerType: containerTypes.GENERICFORM,
                cssClass: "user-info-table",
                position: 1,
                elements: [
                    { name: "Nachname / Pseudonym", value: "lastName", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER, required: true },
                    { name: "Vorname", value: "firstName", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Alter", value: "age", inputType: inputTypes.NUMBER, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Anonymer Kontakt", value: "anonymousContact", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER, style: "slider", export: true },
                    { name: "Str. + Hausnr", value: "addressStreet", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Ort", value: "addressCity", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Telefon", value: "phone", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "E-Mail", value: "mail", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                ]
            },
            {
                containerType: containerTypes.ACCORDION,
                containerLayout: containerLayouts.GRID3,
                position: 2,
                cssClass: "sociodemographic",
                header: "Soziodemografische Angaben",
                elements: [
                    { name: "Geschlecht", value: "gender", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, filter: 1, export: true },
                    { name: "Erwerbstätigkeit", value: "employment", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Wohnform", value: "typeOfHousing", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Staatsangehörigkeit", value: "nationality", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Herkunft der Beratungsanfragen", value: "arrangedBy", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Vorrangige Unterstützungsform", value: "typeOfCare", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Muttersprache", value: "motherTongue", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Vorrangige Behinderung", value: "priorityDisability", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Geflüchtete / Schutzsuchende", value: "refugee", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.CHECKBOX, export: true },
                    { name: "Gebärdensprache", value: "signLanguage", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.CHECKBOX, export: true },
                    { name: "Andere Kommunikationsform", value: "otherCommunication", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.CHECKBOX, export: true },
                    { name: "Rechtliche Vertretung", value: "legalRepresentation", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.CHECKBOX, export: true },

                ]
            },
            {
                containerType: containerTypes.GENERICFORM,
                containerLayout: containerLayouts.GRID3,
                cssClass: "multiple-disabilities",
                header: "Bei Mehrfachbehinderung bitte weitere Behinderungen angeben:",
                position: 3,
                elements: [
                    { name: "Geistige Behinderung", value: "mentalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER, deactivateDisability: 2 },
                    { name: "Körperliche Behinderung", value: "physicalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER, deactivateDisability: 3 },
                    { name: "Seelische Behinderung", value: "psychologicalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER, deactivateDisability: 5 },
                    { name: "Sinnesbehinderung", value: "sensoryDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER, deactivateDisability: 4 },
                    { name: "Autismus", value: "autism", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER, deactivateDisability: 6 },
                ]
            },
            {
                containerType: containerTypes.ACCORDION,
                containerLayout: containerLayouts.LIST,
                position: 4,
                header: "Weitere Informationen",
                elements: [
                    { name: "Standort", value: "projectLocation", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, specialTypeField: specialTypes.WITHDEPENDENTSELECTION, dependentSelection: 'currentUser.userProject.projectLocation' },
                    { name: "Beratungsverlauf", value: "consultationNotes", inputType: inputTypes.MULTILINE, eventHandler: eventHandlers.GENERICHANDLER, maxRows: 6 }
                ]
            },
            {
                containerType: containerTypes.ADDROWTABLECONTAINER,
                position: 5,
                tables: [
                    {
                        tableName: "services",
                        cssClass: "bh-services",
                        elements: [
                            { name: "Leistung", value: "service", required: true, inputType: inputTypes.SELECT, specialTypeField: specialTypes.WITHDISABLECOLUMNS, eventHandler: eventHandlers.GENERICHANDLER, setDateOnSelect: true, setFieldTo: 2, setFieldIf: 2, export: true },
                            { name: "Beratungsaufwand", value: "effortConsultation", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, defaultValue: 1, activeOn: 2, specialTypeField: specialTypes.WITHAUTOFIELDSET },
                            { name: "Datum", value: "date", inputType: inputTypes.DATE, eventHandler: eventHandlers.DATECHANGEHANDLER, specialTypeField: specialTypes.WITHAUTODATESET },
                            { name: "Differenzierung der Leistungen Beratung und Information", value: "differentiationConsultation", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, defaultValue: 1, filter: 1, filterIf: ["service", 2], activeOn: [1, 2] },
                            { name: "Leistung an", value: "serviceFor", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, defaultValue: 2 }
                        ]
                    }
                ]
            }
        ],

        tableSelectConfig: tableSelectConfig,

        //allgemeine auswahl der select boxen
        selectConfigUserData: selectConfigUserData,
    },

    dataCollectionLeisureTime: {
        //modelName: "clientTest",
        containers: [
            {
                containerType: containerTypes.GENERICFORM,
                cssClass: "user-info-table",
                position: 1,
                elements: [
                    { name: "Nachname / Pseudonym", value: "lastName", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER, required: true },
                    { name: "Vorname", value: "firstName", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Alter", value: "age", inputType: inputTypes.NUMBER, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Anonymer Kontakt", value: "anonymousContact", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER, style: "slider", export: true },
                    { name: "Str. + Hausnr", value: "addressStreet", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Ort", value: "addressCity", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Telefon", value: "phone", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "E-Mail", value: "mail", inputType: inputTypes.TEXT, eventHandler: eventHandlers.GENERICHANDLER },
                ]
            },
            {
                containerType: containerTypes.ACCORDION,
                containerLayout: containerLayouts.GRID3,
                cssClass: "sociodemographic",
                position: 2,
                header: "Soziodemografische Angaben",
                elements: [
                    { name: "Geschlecht", value: "gender", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, filter: 1, export: true },
                    { name: "Staatsangehörigkeit", value: "nationality", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Muttersprache", value: "motherTongue", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.SELECT, filter: 1, export: true },
                    { name: "Geflüchtete / Schutzsuchende", value: "refugee", eventHandler: eventHandlers.GENERICHANDLER, inputType: inputTypes.CHECKBOX, export: true },
                ]
            },
            {
                containerType: containerTypes.GENERICFORM,
                containerLayout: containerLayouts.GRID3,
                cssClass: "multiple-disabilities",
                header: "Bei Mehrfachbehinderung bitte weitere Behinderungen angeben:",
                position: 3,
                elements: [
                    { name: "Geistige Behinderung", value: "mentalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Körperliche Behinderung", value: "physicalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Seelische Behinderung", value: "psychologicalDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Sinnesbehinderung", value: "sensoryDisability", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                    { name: "Autismus", value: "autism", inputType: inputTypes.CHECKBOX, eventHandler: eventHandlers.GENERICHANDLER },
                ]
            },
            {
                containerType: containerTypes.ACCORDION,
                containerLayout: containerLayouts.LIST,
                position: 4,
                header: "Weitere Informationen",
                elements: [
                    { name: "Standort", value: "projectLocation", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, specialTypeField: specialTypes.WITHDEPENDENTSELECTION, dependentSelection: 'currentUser.userProject.projectLocation' },
                    { name: "Beratungsverlauf", value: "consultationNotes", inputType: inputTypes.MULTILINE, eventHandler: eventHandlers.GENERICHANDLER, maxRows: 6 }
                ]
            },
            {
                containerType: containerTypes.ADDROWTABLECONTAINER,
                position: 5,
                tables: [
                    {
                        tableName: "services",
                        cssClass: "bh-services",
                        elements: [
                            { name: "Leistung", value: "service", required: true, inputType: inputTypes.SELECT, specialTypeField: specialTypes.WITHDISABLECOLUMNS, eventHandler: eventHandlers.GENERICHANDLER, setDateOnSelect: true, setFieldTo: 2, setFieldIf: 2, export: true },
                            { name: "Beratungsaufwand", value: "effortConsultation", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, defaultValue: 1, activeOn: 2, specialTypeField: specialTypes.WITHAUTOFIELDSET },
                            { name: "Datum", value: "date", inputType: inputTypes.DATE, eventHandler: eventHandlers.DATECHANGEHANDLER, specialTypeField: specialTypes.WITHAUTODATESET },
                            { name: "Differenzierung der Leistungen Beratung und Information", value: "differentiationConsultation", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, defaultValue: 1, filter: 1, filterIf: ["service", 2], activeOn: [1, 2] },
                            { name: "Leistung an", value: "serviceFor", inputType: inputTypes.SELECT, eventHandler: eventHandlers.GENERICHANDLER, defaultValue: 2 }
                        ]
                    }
                ]
            }
        ],

        tableSelectConfig: tableSelectConfig,

        //allgemeine auswahl der select boxen
        selectConfigUserData: selectConfigUserData,
    },

    project: projectData,

    projectHousingBrokerage: {
        tabs: [
            projectData,
            {
                name: "Kooperation, Vernetzung, Öffentlichkeitsarbeit",
                key: "cooperation",
                containers: [
                    {
                        containerType: containerTypes.BASICTABLECONTAINER,
                        tableHead: [{ name: "", align: "left" }, { name: "Anzahl", align: "center" }],
                        position: 1,
                        elements: [
                            [{ name: "Trägerkontakte", inputType: inputTypes.DISPLAY }, { value: "holderContacts", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon telefonisch", inputType: inputTypes.DISPLAY }, { value: "holderContactsPhone", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon vor Ort", inputType: inputTypes.DISPLAY }, { value: "holderContactsOnSite", inputType: inputTypes.NUMBER }],
                        ]
                    },
                    {
                        containerType: containerTypes.BASICTABLECONTAINER,
                        tableHead: [{ name: "", align: "left" }, { name: "Anzahl", align: "center" }],
                        position: 2,
                        elements: [
                            [{ name: "Platzmanagement", inputType: inputTypes.DISPLAY }, { value: "spaceManagement", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon gemeldete Wohnstätten-Plätze", inputType: inputTypes.DISPLAY }, { value: "spaceManagementResidences", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon gemeldete Wohngemeinschaftsplätze", inputType: inputTypes.DISPLAY }, { value: "spaceManagementShared", inputType: inputTypes.NUMBER }],
                        ]
                    },
                    {
                        containerType: containerTypes.BASICTABLECONTAINER,
                        tableHead: [{ name: "", align: "left" }, { name: "Anzahl", align: "center" }],
                        position: 3,
                        elements: [
                            [{ name: "Projektmanagement", inputType: inputTypes.DISPLAY }, { value: "projectManagement", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Koordinierungstreffen / Fallbesprechung", inputType: inputTypes.DISPLAY }, { value: "projectManagementCoordination", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Plenumssitzungen", inputType: inputTypes.DISPLAY }, { value: "projectManagementPlenum", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Beiratssitzungen", inputType: inputTypes.DISPLAY }, { value: "projectManagementAdvisory", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon telefonische Kontakte, Fax, Mail", inputType: inputTypes.DISPLAY }, { value: "projectManagementContacts", inputType: inputTypes.NUMBER }],
                        ]
                    },
                    {
                        containerType: containerTypes.BASICTABLECONTAINER,
                        tableHead: [{ name: "", align: "left" }, { name: "Anzahl", align: "center" }],
                        position: 4,
                        elements: [
                            [{ name: "Gremienarbeit / Fachveranstaltungen", inputType: inputTypes.DISPLAY }, { value: "committeeWork", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon themenspezifische Fachgespräche", inputType: inputTypes.DISPLAY }, { value: "committeeWorkTechnicalDiscussions", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon PSAG", inputType: inputTypes.DISPLAY }, { value: "committeeWorkPSAG", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Andere", inputType: inputTypes.DISPLAY }, { value: "committeeWorkOthers", inputType: inputTypes.NUMBER }],
                        ]
                    },
                    {
                        containerType: containerTypes.BASICTABLECONTAINER,
                        tableHead: [{ name: "", align: "left" }, { name: "Anzahl", align: "center" }],
                        position: 5,
                        elements: [
                            [{ name: "Öffentlichkeitsarbeit", inputType: inputTypes.DISPLAY }, { value: "publicRelation", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Präsentation des Projektes (Tagungen, Messen etc.)", inputType: inputTypes.DISPLAY }, { value: "publicRelationPresentation", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Erstellen von Informationsmaterial (Flyer etc.)", inputType: inputTypes.DISPLAY }, { value: "publicRelationInformation", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Veröffentlichung von Fachartikeln", inputType: inputTypes.DISPLAY }, { value: "publicRelationSpecialistArticles", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Internetpräsenz und Pflege der Website", inputType: inputTypes.DISPLAY }, { value: "publicRelationInternetPresence", inputType: inputTypes.NUMBER }],
                            [{ name: "   davon Kontakte zu Schulen, Werkstätten u.ä. Einrichtungen", inputType: inputTypes.DISPLAY }, { value: "publicRelationContacts", inputType: inputTypes.NUMBER }],

                        ]
                    },
                ]
            }
        ]
    },

    projectLeisureActivities: {
        tabs: [
            projectData,
            {
                name: "Qualität",
                key: "quality",
                containers: [
                    {
                        containerType: containerTypes.ACCORDION,
                        header: "Qualität",
                        cssClass: "bh-project-quality",
                        position: 1,
                        elements: [
                            {
                                name: "a) Es gibt ein formuliertes Leitbild, das für das Freizeitobjekt gültig ist und das als verbindliche Orientierung Grundlage des Handelns ist.",
                                value: "qualityA",
                                inputType: inputTypes.RADIO,
                                eventHandler: eventHandlers.GENERICHANDLER,
                                radios: [{ name: "zutreffend", value: "applicable" }, { name: "nicht zutreffend", value: "notApplicable" }]
                            },
                            {
                                name: "b) Es besteht ein Qualitätsmanagementsystem, in das auch das Freizeitprojekt eingebunden ist.",
                                value: "qualityB",
                                inputType: inputTypes.RADIO,
                                radios: [{ name: "zutreffend", value: "applicable" }, { name: "in Vorbereitung", value: "inPreparation" }]
                            },
                            { name: "Anderes", value: "qualityB", inputType: inputTypes.MULTILINE, eventHandler: eventHandlers.GENERICHANDLER, maxRows: 3 },
                            {
                                name: "c) Ergebnisse von Teilnehmerbefragungen werden ausgewertet und beeinflussen die Programmgestaltung und - durchführung",
                                value: "qualityC",
                                inputType: inputTypes.RADIO,
                                radios: [{ name: "grundsätzlich und durchgängig", value: "fundamentallyConsistently" }]
                            },
                            { name: "eingeschränkt, weil", value: "qualityC", inputType: inputTypes.MULTILINE, eventHandler: eventHandlers.GENERICHANDLER, maxRows: 3 },
                        ]
                    },
                    {
                        containerType: containerTypes.ACCORDION,
                        header: "2. Trägerangaben aufgrund von Teilnehmerrückmeldungen",
                        position: 2,
                        cssClass: "bh-project-holderdata",
                        elements: [
                            {
                                name: "a) Eine Teilnehmer*innenbefragung wird durchgeführt",
                                value: "holderDataA",
                                inputType: inputTypes.RADIO,
                                eventHandler: eventHandlers.GENERICHANDLER,
                                radios: [{ name: "Regelmäßig (jährlich)", value: "annual" }, { name: "Alle zwei Jahre", value: "everyTwoYears" }, { name: "Punktuell und zu besonderen Anlässen", value: "selectively" }]
                            },
                            { name: "Nein, weil", value: "holderDataA", inputType: inputTypes.MULTILINE, eventHandler: eventHandlers.GENERICHANDLER, maxRows: 3 },
                            {
                                name: "b) Die Form der Teilnehmer*innenbefragung erfolgt durch:",
                                value: "holderDataB",
                                inputType: inputTypes.RADIO,
                                eventHandler: eventHandlers.GENERICHANDLER,
                                radios: [{ name: "Mündliche Befragung", value: "oralInterview" }, { name: "Fragebögen", value: "questionnaires" }]
                            },
                            { name: "Andere Methoden", value: "holderDataB", inputType: inputTypes.MULTILINE, eventHandler: eventHandlers.GENERICHANDLER, maxRows: 3 },
                            { name: "c) Die folgenden Angaben beruhen auf Rückmeldungen folgender Anzahl von Personen:", inputType: inputTypes.PARAGRAPH },
                            { name: "Anzahl Personen", value: "numberPeopleWithFeedback", inputType: inputTypes.NUMBER },
                            { name: "Prozentualer Anteil der TeilnehmerInnen, die angeben, dass sie durch das Projekt den einzigen, regelmäßigen Kontakt im außerhäuslichen Rahmen haben.", inputType: inputTypes.PARAGRAPH },
                            { name: "%", value: "participantContactOutsideHome", inputType: inputTypes.NUMBER, max: 100, min: 0, decimal: true },
                            { name: "Prozentualer Anteil der TeilnehmerInnen, die angeben, dass sie auf Programmgestaltung und -durchführung Einfluss nehmen konnten und dass ihre Anregungen und Wünsche berücksichtigt wurden.", inputType: inputTypes.PARAGRAPH },
                            { name: "%", value: "participantInfluenceOnProgram", inputType: inputTypes.NUMBER, max: 100, min: 0, decimal: true },
                            { name: "Prozentualer Anteil der TeilnehmerInnen, die angeben, dass sie sich durch die anderen TeilnehmerInnen und Moderatoren/Anleiter geachtet und respektiert fühlen", inputType: inputTypes.PARAGRAPH },
                            { name: "%", value: "participantFeelsRespected", inputType: inputTypes.NUMBER, max: 100, min: 0, decimal: true },
                            { name: "Besonders aussagekräftige Rückmeldungen sind", value: "meaningfullFeedback", inputType: inputTypes.MULTILINE, maxRows: 5 }
                        ]
                    }
                ]
            }
        ]
    },
    event: {
        tabs: [
            event,
            eventStats,
            eventNotes
        ]
    }
}