//import node modules and react modules
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getToken } from '../../../features/user/userSlice';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Workbook, WorkbookFormat } from "igniteui-react-excel";
import { ExcelUtility } from "../../user/excelExport/ExcelUtility";
import * as Icons from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
//Import CSS
import '../../../css/App.css'
//import DWBO Sources
import Headline from '../../../components/Headline';
import { exportData } from '../../../features/admin/dataExportManagementActions';

const AdminExport = () => {
    //const [metadata, setMetadata] = useState(initMetaData);
    //const [isLoading, setLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState("#5a2572");
    const userToken = useSelector(getToken);

    useEffect(() => {
    },[])

    const handleExport = (value) => {
         setLoading(true);
         exportData(userToken, value).then((response) => {
            //new excel file
            const wb = new Workbook(WorkbookFormat.Excel2007);
            const tableName = response.data.tableName;
            //new worksheet in excel file
            const ws = wb.worksheets().add(tableName);

            //header row
            const headerColumns = response.data.headerValues;
            const dataRows = response.data.dataValues;
            for (let i = 0; i < headerColumns.length; i++) {
                const cell = ws.rows(2).cells(i);
                cell.value = headerColumns[i];
                cell.cellFormat.font.bold = true;
    
                ws.columns(i).width = 7000;
            }

            //data rows
            for (let row = 0; row < dataRows.length; row++) {
                let column = 0;
    
                for (let i = 0; i < headerColumns.length; i++) {
                    const cell = ws.rows(3 + row).cells(column);
                    cell.value = dataRows[row][headerColumns[i]] ? dataRows[row][headerColumns[i]] : "/";
                    column++;
                }
            }

            ExcelUtility.save(wb, tableName).then(
                        (f) => {
                            setLoading(false);
                            console.log("Saved:" + f);
                        },
                        (err) => {
                            setLoading(false);
                            console.error("ExcelUtility.Save Error:" + err);
                        }
                    );
                    setLoading(false);
        }).catch((err) => {
            console.log(err);
            //responseNotifyHandling(err.response, err.response.status, `Fehler [${err.response.status}]: ${err.response.data.message}`);
        });
    }

    //fetched data as json from db
    useEffect(() => {
        //setLoading(true);
        //getData();
    }, []);

    return (
            <div id="menu-options">
                    <Headline name='Datenexport' />
                    <div className='export-screen'>
                        <div className='row' style={{ marginTop: '1.25em' }}>
                            <div className='col'>
                                <Link className="card-link" onClick={() => handleExport(1)} style={{ width: '80%' }}>
                                    <Card className='dwbo-card'>
                                        <Card.Header>Klient*innen Export</Card.Header>
                                        <Card.Body>
                                            <Card.Title><Icons.FaRegAddressCard size={70} /></Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className='col'>
                                <Link className="card-link" onClick={() => handleExport(2)} style={{ width: '80%' }}>
                                    <Card className='dwbo-card'>
                                        <Card.Header>Leistungen Export</Card.Header>
                                        <Card.Body>
                                            <Card.Title><Icons.FaSignal size={70} /></Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        </div>
                        <div className='row' style={{ marginTop: '1.25em' }}>
                            <div className='col'>
                                <Link className="card-link" onClick={() => handleExport(3)} style={{ width: '80%' }}>
                                    <Card className='dwbo-card'>
                                        <Card.Header>Zielreichungen Export</Card.Header>
                                        <Card.Body>
                                            <Card.Title><Icons.FaChartLine size={70} /></Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className='col'>
                                <Link className="card-link" onClick={() => handleExport(4)} style={{ width: '80%' }}>
                                    <Card className='dwbo-card'>
                                        <Card.Header>Problemfelder Export</Card.Header>
                                        <Card.Body>
                                            <Card.Title><Icons.FaCubes size={70} /></Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        </div>
                        <ClipLoader className='export-loader' color={color} loading={loading} size={150} />
                    </div>
                </div>
    );
};
export default AdminExport;